import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Switch } from 'react-router';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../components/Footer';
import history from '../redux/lib/history';
import PrivateRoute from './privateRoute';
import { PublicRoute } from './publicRoute';
import {
  Login,
  ActivateAccountScreen,
  ResetPassword,
  Apply,
  ViewJobDetails,
  InterviewFeedback,
  CandidateProfile,
  ApplyFromThirdParty,
  PublicJobDetails,
} from './loadableComponents';
import { getTenantConfig } from '../redux/actions';
import {
  strictValidObjectWithKeys,
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
} from '../utils/commonUtils';

function MainRoutes({ callTenantConfigAPI, tenantConfig, configLoad }) {
  const callConfigAPI = async () => {
    await callTenantConfigAPI();
  };

  useEffect(() => {
    callConfigAPI();
  }, []);

  return (
    <>
      {!configLoad &&
        strictValidObjectWithKeys(tenantConfig) &&
        validObjectWithParameterKeys(tenantConfig, ['logo_favicon']) && (
          <Helmet>
            {strictValidArrayWithLength(tenantConfig.logo_favicon) &&
              tenantConfig.logo_favicon.map((v, i) => (
                <link key={i} rel="shortcut icon" sizes={v.key} href={v.url} />
              ))}
            <title>{`${tenantConfig.name} - Ajna`}</title>
          </Helmet>
        )}
      <ConnectedRouter key="publicroutes" history={history}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute
            exact
            path="/activate-invite-account/:key"
            component={ActivateAccountScreen}
          />
          <PublicRoute
            exact
            path="/activate-account/:key"
            component={ActivateAccountScreen}
          />
          <PublicRoute
            exact
            path="/reset-password/:key"
            component={ResetPassword}
          />
          <PublicRoute
            exact
            path="/view-job-details/:jobId/:candidateId"
            component={ViewJobDetails}
            isExternalRoute
          />
          <PublicRoute
            exact
            path="/view-job-details/:jobId"
            component={PublicJobDetails}
            isExternalRoute
          />
          <PublicRoute
            exact
            path="/candidates/detail/:candidateId"
            component={CandidateProfile}
            isExternalRoute
          />
          <PublicRoute
            exact
            path="/:type/feedback/:appointmentid/:key"
            component={InterviewFeedback}
            isExternalRoute
          />
          {/**
           * Same Route to handle the each possible parameters
           * /apply
           * /apply/:type/:id
           * /apply/:type
           */}
          <PublicRoute exact path="/apply" component={Apply} />
          <PublicRoute
            exact
            path="/apply/:type/:id"
            component={ApplyFromThirdParty}
            isExternalRoute
          />
          <PublicRoute
            exact
            path="/apply/:type"
            component={ApplyFromThirdParty}
            isExternalRoute
          />
          <PrivateRoute path="/" />
        </Switch>
      </ConnectedRouter>
      <Footer />
    </>
  );
}

MainRoutes.defaultProps = {
  tenantConfig: {},
  configLoad: false,
};

const mapStateProps = (state) => ({
  tenantConfig: state.get('config').get('tenantConfig'),
  configLoad: state.get('config').get('isLoad'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callTenantConfigAPI: (...params) => dispatch(getTenantConfig(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(MainRoutes);
